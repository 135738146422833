import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    ListItemIcon,
    ListItemText,
    Paper,
    PaperProps,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import { Breakpoint, useTheme } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import Draggable from 'react-draggable';

export type DialogType = Omit<DialogProps, 'aria-labelledby' | 'aria-describedby'> & {
    id: string;
    fullscreenAtBreakpoint?: Breakpoint | number;
    draggable?: boolean;
    icon?: ReactNode;
    title?: ReactNode;
    neutralAction?: ReactNode;
    positiveAction?: ReactNode;
    negativeAction?: ReactNode;
};

const Dialog: React.FC<DialogType> = ({
    id,
    open,
    fullScreen,
    fullscreenAtBreakpoint,
    draggable,
    icon,
    title,
    children,
    scroll,
    neutralAction,
    positiveAction,
    negativeAction,
    ...others
}): JSX.Element => {
    const theme = useTheme();
    const mqFullscreen = useMediaQuery(fullscreenAtBreakpoint ? theme.breakpoints.down(fullscreenAtBreakpoint) : '');
    const isPrimitiveChildren = children instanceof String || children instanceof Number || children instanceof Boolean;
    const hasActions = neutralAction || positiveAction || negativeAction;
    const ariaLabelledBy = `${id}-dialog-title`;
    const ariaDescribedBy = `${id}-dialog-description`;

    const DraggablePaperComponent = useMemo(() => {
        return (props: PaperProps): JSX.Element => {
            return draggable ? (
                <Draggable handle={ariaLabelledBy} cancel={'[class*="MuiDialogContent-root"]'}>
                    <Paper {...props} />
                </Draggable>
            ) : (
                <></>
            );
        };
    }, [ariaLabelledBy, draggable]);

    return (
        <MuiDialog
            fullScreen={fullScreen || Boolean(fullscreenAtBreakpoint && mqFullscreen)}
            open={open}
            scroll={scroll}
            aria-labelledby={title || draggable ? ariaLabelledBy : undefined}
            aria-describedby={isPrimitiveChildren ? ariaDescribedBy : undefined}
            PaperComponent={draggable ? DraggablePaperComponent : undefined}
            {...others}
        >
            {!!(icon || title) && (
                <DialogTitle id={ariaLabelledBy} component={Stack} direction="row" spacing={2}>
                    {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
                    {!!title && (
                        <ListItemText>
                            <Typography variant="h5">{title}</Typography>
                        </ListItemText>
                    )}
                </DialogTitle>
            )}
            {children && (
                <DialogContent dividers={scroll === 'paper'}>
                    {isPrimitiveChildren ? (
                        <DialogContentText id={`${id}-dialog-description`}>{String(children)}</DialogContentText>
                    ) : (
                        <>{children}</>
                    )}
                </DialogContent>
            )}
            {hasActions && (
                <DialogActions sx={{ width: '100%' }}>
                    {neutralAction}
                    {neutralAction && <Box sx={{ width: '100%' }} />}
                    {negativeAction}
                    {positiveAction}
                </DialogActions>
            )}
        </MuiDialog>
    );
};

export default Dialog;
