// material-ui
import { Box, Chip, Typography } from '@mui/material';
import Image from 'components/Image';
import { WhitelabellingContext } from 'layout/Whitelabelling';
import { useContext } from 'react';
import config from '../../config';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo: React.FC = (): JSX.Element => {
    const { name } = useContext(WhitelabellingContext);
    return (
        <Box
            width="100%"
            boxSizing="border-box"
            paddingLeft=".5rem"
            paddingRight=".5rem"
            display="flex"
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
            gap=".5rem"
        >
            <Image src={[`/domains/${document.location.hostname}/logo.png`, '/logo.png']} alt={name} width="30" height="30" />
            <Typography variant="h4" fontFamily="Public Sans" whiteSpace="pre-line" textAlign="center">
                {name}
            </Typography>
            <Chip label={config.reactAppVersion} size="small" sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem' } }} />
        </Box>
    );
};

export default Logo;
