import axios, { AxiosDefaults, AxiosError, AxiosInstance, AxiosRequestHeaders, AxiosResponse, HeadersDefaults } from 'axios';
import { logout } from 'store/reducers/auth';
import { getErrorMessage } from 'utils/errors';
import { dispatch } from '../store';

const http: AxiosInstance = axios.create();
const defaults: AxiosDefaults<unknown> = http.defaults;
const headers: HeadersDefaults = defaults.headers;
const postHeaders: AxiosRequestHeaders = headers.post;

defaults.baseURL = 'localhost';
defaults.timeout = 10000;

postHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
postHeaders.Accept = '*/*';

http.interceptors.response.use(
    (response: AxiosResponse): Promise<AxiosResponse> => {
        return Promise.resolve(response);
    },
    (error: AxiosError): Promise<AxiosError> => {
        if (error?.response?.status === 401) {
            try {
                const message = getErrorMessage(error.response.data);
                if (message === 'registration_expired') {
                    console.log('401 registration_expired - RELOAD');
                    window.location.reload();
                } else {
                    console.log('401 - FORCE LOGOUT');
                    dispatch(logout());
                }
            } catch (error) {
                dispatch(logout());
            }
        }
        return Promise.reject(error);
    }
);

export default http;
