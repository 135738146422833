export const INTERNATIONAL_PREFIXES: Record<string, number> = {
    albania: 355,
    andorra: 376,
    austria: 43,
    belarus: 375,
    belgium: 32,
    bosnia_herzegovina: 387,
    bulgaria: 359,
    croatia: 385,
    cyprus: 357,
    czech_republic: 420,
    denmark: 45,
    estonia: 372,
    faroe_islands: 298,
    finland: 358,
    france: 33,
    germany: 49,
    gibraltar: 350,
    greece: 30,
    hungary: 36,
    iceland: 354,
    ireland: 353,
    italy: 39,
    kosovo: 383,
    latvia: 371,
    liechtenstein: 423,
    lithuania: 370,
    luxembourg: 352,
    macedonia: 389,
    malta: 356,
    moldova: 373,
    monaco: 377,
    montenegro: 382,
    netherlands: 31,
    norway: 47,
    poland: 48,
    portugal: 351,
    romania: 40,
    russia: 7,
    san_marino: 378,
    serbia: 381,
    slovakia: 421,
    slovenia: 386,
    spain: 34,
    sweden: 46,
    switzerland: 41,
    turkey: 90,
    ukraine: 380,
    united_kingdom: 44,
    vatican_city: 39
};

export const PREFIXES_LANGUAGE: Record<number, string> = {
    33: 'fr',
    39: 'it'
};

export const getInternationalPrefix = (phone: string) => {
    const number = phone?.replace(/^\+/g, '') || '';
    return Object.values(INTERNATIONAL_PREFIXES).find((prefix) => number.startsWith(String(prefix)));
};

export const hasInternationalPrefix = (phone: string) => {
    const number = phone?.replace(/^\+/g, '') || '';
    return !!Object.values(INTERNATIONAL_PREFIXES).find((prefix) => number.startsWith(String(prefix)));
};

export const removeInternationalPrefix = (phone: string) => {
    const number = phone?.replace(/^\+/g, '') || '';
    const prefix = Object.values(INTERNATIONAL_PREFIXES).find((prefix) => number.startsWith(String(prefix)));
    return prefix ? number.substring(Math.min(String(prefix).length, number.length), number.length) : number;
};

export const getLanguageByPrefix = (phone: string, _default?: string) => {
    const prefix = getInternationalPrefix(phone);
    return prefix && prefix in PREFIXES_LANGUAGE ? PREFIXES_LANGUAGE[prefix] : _default || 'en';
};
