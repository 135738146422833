import { Chip, Stack } from '@mui/material';
import { User } from 'apis/types';
import Logo from 'components/Logo';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from 'store/reducers';
import { addDays, addMonths, formatDate, isAfter, isBefore } from 'utils/dates';

// ==============================|| DRAWER HEADER ||============================== //

export interface DrawerHeaderProps {
    open?: boolean;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ open }): JSX.Element => {
    const loggedUser = useSelector((state: StateType) => state.auth);
    const { t } = useTranslation();

    const [user, setUser] = useState<Partial<User> | undefined>();

    useEffect(() => {
        if (loggedUser) {
            console.log('DRAWER HEADER - USE EFFECT - loggedUser', loggedUser);
            setUser({
                ...loggedUser,
                expiryDate: loggedUser.expiryDate ? new Date(loggedUser.expiryDate) : undefined
            });
        }
    }, [loggedUser]);

    const expiryDate = useMemo(() => (user?.expiryDate ? new Date(user.expiryDate) : undefined), [user]);

    const licenseText = useMemo(() => (user?.expiryDate ? (!user.trial ? 'header_license' : 'header_trial') : undefined), [user]);

    const licenseState = useMemo(
        () =>
            expiryDate
                ? isBefore(expiryDate)
                    ? 'expired'
                    : !isAfter(expiryDate, addMonths(new Date(), 1))
                    ? 'expiring'
                    : 'valid'
                : undefined,
        [expiryDate]
    );

    const color = useMemo(() => {
        return licenseState === 'expired'
            ? 'error'
            : licenseState === 'expiring'
            ? 'warning'
            : licenseState === 'valid'
            ? 'success'
            : undefined;
    }, [licenseState]);

    const licenseChip = useMemo(() => {
        console.log('DRAWER HEADER - LICENSE CHIP - DATA', expiryDate, licenseText, licenseState);
        return (
            expiryDate &&
            licenseText &&
            licenseState && (
                <Chip
                    label={`${t(licenseText)} ${t(`${licenseText}_${licenseState}`, {
                        expiryDate: formatDate(addDays(expiryDate, -1), 'DD/MM/YYYY')
                    })}`}
                    color={color}
                    size="small"
                    sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem' } }}
                />
            )
        );
    }, [color, expiryDate, licenseState, licenseText, t]);

    return (
        <Stack spacing={1} pt={1} alignItems="center">
            <Logo sx={{ width: '100%' }} />
            {licenseChip}
        </Stack>
    );
};

export default DrawerHeader;
