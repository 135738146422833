import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - LINER PROGRESS ||============================== //

const LinearProgress = (): Components<Theme> => {
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 6,
                    borderRadius: 100
                },
                bar: {
                    borderRadius: 100
                }
            }
        }
    };
};

export default LinearProgress;
