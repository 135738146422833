// project import
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LanguageSelector from 'components/LanguageSelector';
import ScrollTop from 'components/ScrollTop';
import AppProvider from 'layout/MainLayout/AppContext/AppProvider';
import { Whitelabelling } from 'layout/Whitelabelling';
import { useTranslation } from 'react-i18next';
import Routes from 'routes';
import ThemeCustomization from 'themes';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App: React.FC = (): JSX.Element => {
    const { i18n } = useTranslation();
    return (
        <Whitelabelling>
            <ThemeCustomization>
                <LanguageSelector>
                    <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.language}>
                        <AppProvider>
                            <ScrollTop>
                                <Routes />
                            </ScrollTop>
                        </AppProvider>
                    </LocalizationProvider>
                </LanguageSelector>
            </ThemeCustomization>
        </Whitelabelling>
    );
};

export default App;
