import { Alert, AlertTitle, Grid, Typography } from '@mui/material';
import { getAll as getAllPrices, Price } from 'apis/price';
import ErrorDialog from 'components/Dialog/ErrorDialog';
import MessageDialog from 'components/Dialog/MessageDialog';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { StateType } from 'store/reducers';
import { addDays, formatDate } from 'utils/dates';
import { getErrorMessage } from 'utils/errors';
import { localizePrice } from 'utils/numbers';
import PaymentOption from './PaymentOption';
import moment from 'moment';

const Renew: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const user = useSelector((state: StateType) => state.auth);

    const [searchParams, setSearchParams] = useSearchParams();

    const [error, setError] = useState<string | undefined>();
    const [price, setPrice] = useState<Price | undefined>();
    const [payment, setPayment] = useState(
        searchParams.has('AMOUNT') && !isNaN(parseFloat(searchParams.get('AMOUNT') || ''))
            ? {
                  amount: parseInt(searchParams.get('AMOUNT') as string) / 100
              }
            : undefined
    );

    useEffect(() => {
        if (payment) {
            Array.from(searchParams.entries()).forEach((param) => searchParams.delete(param[0]));
            setSearchParams(searchParams);
        }
    }, [payment, searchParams, setSearchParams]);

    useEffect(() => {
        void (async () => {
            try {
                if (user.accessToken) {
                    const prices = await getAllPrices(user.accessToken);
                    setPrice(prices);
                }
            } catch (err) {
                setError(getErrorMessage(err));
            }
        })();
    }, [user]);

    const paymentOptions = useMemo(() => {
        const baseMonthPrice = price?.base.find((price) => price.months === 1);
        const baseHalfYearPrice = price?.base.find((price) => price.months === 6);
        const baseYearPrice = price?.base.find((price) => price.months === 12);

        const discountMonthPrice = price?.discount?.find((price) => price.months === 1);
        const discountHalfYearPrice = price?.discount?.find((price) => price.months === 6);
        const discountYearPrice = price?.discount?.find((price) => price.months === 12);

        const vattedMonthPrice = price?.vatted.find((price) => price.months === 1);
        const vattedHalfYearPrice = price?.vatted.find((price) => price.months === 6);
        const vattedYearPrice = price?.vatted.find((price) => price.months === 12);

        const prices = [];

        if (
            vattedMonthPrice?.price &&
            Math.min(vattedHalfYearPrice?.price || Number.MAX_SAFE_INTEGER, vattedYearPrice?.price || Number.MAX_SAFE_INTEGER) >
                vattedMonthPrice.price
        ) {
            prices.push({
                months: 1,
                vat: discountMonthPrice?.vat ?? baseMonthPrice?.vat,
                base: baseMonthPrice,
                discount: discountMonthPrice,
                vatted: vattedMonthPrice
            });
        }

        if (vattedHalfYearPrice?.price && (vattedYearPrice?.price || Number.MAX_SAFE_INTEGER) > vattedHalfYearPrice.price) {
            prices.push({
                months: 6,
                vat: discountHalfYearPrice?.vat ?? baseHalfYearPrice?.vat,
                base: baseHalfYearPrice,
                discount: discountHalfYearPrice,
                vatted: vattedHalfYearPrice
            });
        }

        if (vattedYearPrice?.price) {
            prices.push({
                months: 12,
                vat: discountYearPrice?.vat ?? baseYearPrice?.vat,
                base: baseYearPrice,
                discount: discountYearPrice,
                vatted: vattedYearPrice
            });
        }

        return prices;
    }, [price]);

    const licenseWarning = useMemo(
        () =>
            user?.expiryDate &&
            new Date(user.expiryDate) < new Date() && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        <AlertTitle>{t('warning')}</AlertTitle>
                        {t(user.trial ? 'license_warning_trial_expiration_message' : 'license_warning_expiration_message', {
                            expiryDate: formatDate(addDays(moment(user.expiryDate).toDate(), -1), 'DD/MM/YYYY') // La data da mostrare è quella dell'ultimo giorno di validità incluso
                        })}
                    </Alert>
                </Grid>
            ),
        [t, user]
    );

    const paymentOptionsNodes = useMemo(
        () =>
            paymentOptions.map((option) => (
                <Grid item xs={12} sm={12 / paymentOptions.length}>
                    <PaymentOption
                        trial={user?.trial}
                        price={option.base?.price ?? 0}
                        discountedPrice={option.discount?.price}
                        vattedPrice={option.vatted?.price ?? 0}
                        vat={option.vat ?? 0}
                        description=""
                        months={option.months}
                        setError={setError}
                        priceList={price as Price}
                    />
                </Grid>
            )),
        [paymentOptions, price, user]
    );

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ height: '80%' }}>
                {licenseWarning}
                <Grid item xs={12}>
                    <Typography variant="h1" textAlign="center">
                        {t(user?.trial ? 'license_subscribe_title' : 'license_renew_title')}
                    </Typography>
                </Grid>
                {paymentOptionsNodes}
            </Grid>
            <MessageDialog
                id="payment-success"
                title={t(user?.trial ? 'license_subscribe_payment_success_title' : 'license_renew_payment_success_title')}
                open={!!payment}
                onContinue={() => setPayment(undefined)}
            >
                <Typography variant="h6">
                    {t(user?.trial ? 'license_subscribe_payment_success_message' : 'license_renew_payment_success_message', {
                        amount: localizePrice(payment?.amount || 0)
                    })}
                </Typography>
            </MessageDialog>
            {error && <ErrorDialog open={!!error} error={error} onContinue={() => setError(undefined)} />}
        </>
    );
};

export default Renew;
