import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - CARD CONTENT ||============================== //

const CardContent = (): Components<Theme> => {
    return {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 20,
                    '&:last-child': {
                        paddingBottom: 20
                    }
                }
            }
        }
    };
};

export default CardContent;
