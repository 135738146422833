import ErrorIcon from '@mui/icons-material/Error';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogType } from '.';

type ErrorDialogType = Omit<DialogType, 'id'> & {
    id?: string;
    onContinue?: () => void;
    error?: Error | string;
};

const ErrorDialog: React.FC<ErrorDialogType> = ({ id, title, onContinue, neutralAction, children, error, ...props }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Dialog
            id={`error${id ? `-${id}` : ''}`}
            title={title ? title : t('warning')}
            onClick={(e) => e.stopPropagation()}
            neutralAction={
                neutralAction
                    ? neutralAction
                    : onContinue && (
                          <Button
                              onClick={(e) => {
                                  e.stopPropagation();
                                  onContinue();
                              }}
                          >
                              <Typography variant="h6">{t('continue')}</Typography>
                          </Button>
                      )
            }
            {...props}
        >
            {children || (
                <Stack spacing={2} direction="row">
                    <ErrorIcon color="error" sx={{ fontSize: '3rem' }} />
                    <Stack>
                        <Typography variant="h5">
                            {t('error_occured')}
                            {((error instanceof Error && error.message) || error) && ':'}
                        </Typography>
                        {((error instanceof Error && error.message) || error) && (
                            <Typography variant="h6">{error instanceof Error ? error.message : error}</Typography>
                        )}
                    </Stack>
                </Stack>
            )}
        </Dialog>
    );
};

export default ErrorDialog;
