// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import config from 'config';
import { Link } from 'react-router-dom';
// project import
import Logo from './Logo';

// ==============================|| MAIN LOGO ||============================== //

export interface LogoSectionProps {
    sx?: SxProps<Theme>;
    to?: string;
}

const LogoSection: React.FC<LogoSectionProps> = ({ sx, to }): JSX.Element => (
    <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
        <Logo />
    </ButtonBase>
);

export default LogoSection;
