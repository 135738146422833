import { Components } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

const ListItemText = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: theme.palette.grey[900]
                }
            }
        }
    };
};

export default ListItemText;
