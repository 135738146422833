import { PalettesProps } from '@ant-design/colors';
import { CustomColor as LabeledColor, CustomPalette } from 'themes';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

export type SimpleColor = string[] & {
    primary?: string | undefined;
};

const toLabeledColor = (color: SimpleColor): LabeledColor => ({
    0: color[0],
    50: color[1],
    100: color[2],
    200: color[3],
    300: color[4],
    400: color[5],
    500: color[6],
    600: color[7],
    700: color[8],
    800: color[9],
    900: color[10],
    A50: color[15],
    A100: color[11],
    A200: color[12],
    A400: color[13],
    A700: color[14],
    A800: color[16]
});

const Theme = (colors: PalettesProps): Partial<CustomPalette> => {
    const { blue, red, gold, cyan, green, grey, geekblue } = colors;

    const greyColors = toLabeledColor(grey);
    const cyanColors = toLabeledColor(cyan);

    const contrastText = '#fff';

    return {
        primary: {
            lighter: blue[0],
            100: blue[1],
            200: blue[2],
            light: blue[3],
            400: blue[4],
            main: blue[5],
            dark: blue[6],
            700: blue[7],
            darker: blue[8],
            900: blue[9],
            contrastText
        },
        secondary: {
            lighter: cyanColors[100],
            100: cyanColors[100],
            200: cyanColors[200],
            light: cyanColors[300],
            400: cyanColors[400],
            main: cyanColors[500],
            600: cyanColors[600],
            dark: cyanColors[700],
            800: cyanColors[800],
            darker: cyanColors[900],
            A100: cyanColors[0],
            A200: cyanColors.A400,
            A300: cyanColors.A700,
            contrastText: grey[0]
        },
        error: {
            lighter: red[0],
            light: red[2],
            main: red[4],
            dark: red[7],
            darker: red[9],
            contrastText
        },
        warning: {
            lighter: gold[0],
            light: gold[3],
            main: gold[5],
            dark: gold[7],
            darker: gold[9],
            contrastText: greyColors[100]
        },
        info: {
            lighter: geekblue[0],
            light: geekblue[3],
            main: geekblue[5],
            dark: geekblue[7],
            darker: geekblue[9],
            contrastText
        },
        success: {
            lighter: green[0],
            light: green[3],
            main: green[5],
            dark: green[7],
            darker: green[9],
            contrastText
        },
        grey: greyColors
    };
};

export default Theme;
