// assets
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { List, ListItemButton, ListItemIcon, ListItemText, Theme, useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import InviteButton from 'components/invitation/InviteButton';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { activeItem } from 'store/reducers/menu';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

export interface ProfileTabProps {
    handleLogout?: MouseEventHandler;
}

const ProfileTab: React.FC<ProfileTabProps> = ({ handleLogout }): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [navigateTo, setNavigateTo] = useState<string | undefined>();
    const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        if (navigateTo) setNavigateTo(undefined);
    }, [navigateTo]);

    const handleListItemClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
            setSelectedIndex(index);
            if (index === 1) {
                setNavigateTo('/profile');
                dispatch(activeItem({ openItem: ['profile'] }));
            }
        },
        [dispatch]
    );

    return (
        <>
            <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
                {matchesXs && (
                    <InviteButton type="menu" selected={selectedIndex === 0} onMenuItemClick={(e) => handleListItemClick(e, 0)} />
                )}
                {!location.pathname.startsWith('/profile') && (
                    <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                        <ListItemIcon>
                            <UserOutlined />
                        </ListItemIcon>
                        <ListItemText primary={t('header_profile')} />
                    </ListItemButton>
                )}
                <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t('header_logout')} />
                </ListItemButton>
            </List>
            {navigateTo && <Navigate to={navigateTo} />}
        </>
    );
};

export default ProfileTab;
