import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - LINK ||============================== //

const Link = (): Components<Theme> => {
    return {
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            }
        }
    };
};

export default Link;
