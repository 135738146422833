// third-party
import SyntaxHighlighter, { SyntaxHighlighterProps } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// ==============================|| CODE HIGHLIGHTER ||============================== //

const SyntaxHighlight: React.FC<SyntaxHighlighterProps> = ({ children, ...others }): JSX.Element => {
    return (
        <SyntaxHighlighter language="typescript" showLineNumbers style={a11yDark} {...others}>
            {children}
        </SyntaxHighlighter>
    );
};

export default SyntaxHighlight;
