import { Components } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

const TableCell = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    padding: 12,
                    borderColor: theme.palette.divider
                },
                head: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontWeight: 600,
                    paddingTop: 20,
                    paddingBottom: 20
                }
            }
        }
    };
};

export default TableCell;
