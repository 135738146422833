import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

const ListItemIcon = (): Components<Theme> => {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 24
                }
            }
        }
    };
};

export default ListItemIcon;
