import config from 'config';
import i18n from 'lang/I18n';
import moment from 'moment';
import { dispatch } from 'store';
import { update as updateAuth } from 'store/reducers/auth';
import http from './http';
import { ServerResponse, User, UserPassword } from './types';

export const get = async (accessToken: string): Promise<User | undefined> => {
    const url = encodeURI(`${config.serverUrl}/user`);
    console.log('USER - GET - URL', url);
    const response: ServerResponse<User> = await http.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (!response.data.code) throw response.data;

    const user = response.data.object;

    // Aggiornare i dati di autenticazione (in caso siano diversi da quelli già memorizzati)
    dispatch(
        updateAuth({
            ...user,
            expiryDate: user?.expiryDate && String(user.expiryDate)
        })
    );

    // Conversione delle date da stringhe a date
    // (così il tipo TypeScript è coerente con quello osservato in produzione).
    // Questo perché il tipo ritornato dalla REST per le date è in realtà una stringa.
    if (user?.activationDate) {
        user.activationDate = moment(user.activationDate).toDate();
    }
    if (user?.expiryDate) {
        user.expiryDate = moment(user.expiryDate).toDate();
    }
    if (user?.whatsappActivationDate) {
        user.whatsappActivationDate = moment(user.whatsappActivationDate).toDate();
    }
    if (user?.whatsappExpiryDate) {
        user.whatsappExpiryDate = moment(user.whatsappExpiryDate).toDate();
    }
    if (user?.telegramActivationDate) {
        user.telegramActivationDate = moment(user.telegramActivationDate).toDate();
    }
    if (user?.telegramExpiryDate) {
        user.telegramExpiryDate = moment(user.telegramExpiryDate).toDate();
    }
    return response.data.object;
};

export const update = async (accessToken: string, user: Partial<User & UserPassword>): Promise<User | undefined> => {
    const url = encodeURI(`${config.serverUrl}/user/update`);
    const { id, username, refreshToken, accessToken: removedAccessToken, ...userData } = user;
    if (!userData.welcomeMessage) {
        userData.welcomeMessage = i18n.t('transcription_default_welcome_message');
    }
    console.log('USER - UPDATE - URL', url);
    console.log('USER - UPDATE - DATA', userData);
    const response: ServerResponse<User> = await http.post(url, userData, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    });
    if (!response.data.code) throw response.data;
    return response.data.object;
};
