import { Components } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - TAB ||============================== //

const Tab = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 46,
                    color: theme.palette.text.primary
                }
            }
        }
    };
};

export default Tab;
