import config from 'config';
import http from './http';
import { Price } from './price';
import { BasicServerResponse, ServerResponse } from './types';

type AccountingModeType = 'D' | 'I';
type AuthorModeType = 'I';
type SingleOptionType = 'B' | 'D' | 'G' | 'H' | 'I' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'U' | 'V' | 'W' | 'X';
type OptionsType = SingleOptionType[];
type LockCardType =
    | '01'
    | '02'
    | '04'
    | '06'
    | '07'
    | '08'
    | '49'
    | '80'
    | '81'
    | '82'
    | '89'
    | '91'
    | '92'
    | '94'
    | '96'
    | '97'
    | 'A1'
    | 'CC'
    | 'NC';

export interface PaymentData {
    // Campi che compaiono nell'hash
    urlMs: string;
    urlDone: string;
    orderId: string;
    shopId: string;
    amount: number;
    currency: number;
    exponent?: number;
    accountingMode: AccountingModeType;
    authorMode: AuthorModeType;
    options?: OptionsType;
    name?: string;
    surname?: string;
    taxId?: string;
    lockCard?: LockCardType;
    commis?: string;
    ordDescr?: string;
    vsid?: string;
    opDescr?: string;
    remainingDuration?: number;
    userId?: string;
    phoneNumber?: string;
    causation?: string;
    user?: string;
    productRef?: string;
    antifraud?: string;
    dsData?: string;

    // Questi campi non sono nell'hash
    paymentsUrl: string;
    page: string;
    urlBack: string;
    lang?: string;
    hmac: string;
}

export interface Payment {
    paymentData: PaymentData;
    months: number;
    price: Price;
}

export const get = async (
    accessToken: string,
    { urlBack, urlDone, amount }: Pick<PaymentData, 'urlBack' | 'urlDone' | 'amount'>,
    months: number,
    price: Price
): Promise<Payment | undefined> => {
    const url = encodeURI(`${config.serverUrl}/payments/get-payment-data`);
    console.log('PAYMENTS - GET - URL', url);
    console.log('PAYMENTS - GET - DATA', { urlBack, urlDone, amount });
    const response: ServerResponse<Payment> = await http.post(
        url,
        { urlBack, urlDone, amount, months, price },
        {
            headers: { Authorization: `Bearer ${accessToken}` }
        }
    );
    console.log('Response', response);
    if (!response.data.code) throw response.data;
    return response.data.object;
};

export const makePayment = async (
    accessToken: string,
    paymentData: PaymentData,
    months: number,
    price: Price
): Promise<BasicServerResponse> => {
    const url = encodeURI(`${config.serverUrl}/payments/make-payment`);
    console.log('PAYMENTS - MAKE - URL', url);
    console.log('PAYMENTS - MAKE - DATA', paymentData, months);
    const response: ServerResponse<BasicServerResponse> = await http.post(
        url,
        { paymentData, months, price },
        {
            headers: { Authorization: `Bearer ${accessToken}` }
        }
    );
    console.log('PAYMENTS - MAKE - RESPONSE', response);
    if (!response.data.code) throw response.data;
    return response.data;
};
