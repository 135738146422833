import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import Image from 'components/Image';
import AppContext from 'layout/MainLayout/AppContext';
import { MouseEventHandler, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTheme } from 'themes';

export type InviteButtonProps = {
    type?: 'small' | 'expanded' | 'menu';
    selected?: boolean;
    onButtonClick?: MouseEventHandler<HTMLButtonElement>;
    onMenuItemClick?: MouseEventHandler<HTMLDivElement>;
};

const InviteButton: React.FC<InviteButtonProps> = ({ type = 'small', selected = false, onButtonClick, onMenuItemClick }): JSX.Element => {
    const { t } = useTranslation();
    const theme: CustomTheme = useTheme();
    const { loggedUser, setOpenInviteDialog } = useContext(AppContext);

    const inviteUrl = useMemo(
        () => (loggedUser?.referralCode ? t('invitation_url', { referralCode: loggedUser.referralCode }) : undefined),
        [loggedUser, t]
    );

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            onButtonClick?.(e);
            setOpenInviteDialog?.(true);
        },
        [onButtonClick, setOpenInviteDialog]
    );

    const handleMenuItemClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            onMenuItemClick?.(e);
            setOpenInviteDialog?.(true);
        },
        [onMenuItemClick, setOpenInviteDialog]
    );

    const button = useMemo(
        () =>
            inviteUrl ? (
                type === 'small' ? (
                    <Tooltip title={t('invitation_small_button_tooltip')}>
                        <Button startIcon={<PersonAddIcon />} color="error" variant="contained" onClick={handleButtonClick}>
                            {t('invitation_small_button_label')}
                        </Button>
                    </Tooltip>
                ) : type === 'expanded' ? (
                    <Button variant="contained" color="error" onClick={handleButtonClick}>
                        <Stack alignItems="center" width="100%" sx={{ overflow: 'hidden' }}>
                            <Typography maxWidth="100%" textAlign="center" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
                                {t('invitation_big_button_label_pt1')}
                            </Typography>
                            <Image
                                src={[`/domains/${document.location.hostname}/invitation.png`, '/invitation.png']}
                                alt=""
                                width="100%"
                                height="auto"
                                style={{ maxHeight: '20vh', objectFit: 'cover' }}
                            />
                            <Typography maxWidth="90%" textAlign="center" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
                                {t('invitation_big_button_label_pt2')}
                            </Typography>
                        </Stack>
                    </Button>
                ) : (
                    <ListItemButton
                        selected={selected}
                        onClick={handleMenuItemClick}
                        sx={{
                            backgroundColor: `${theme.palette.error.main} !important`,
                            color: `${theme.palette.error.contrastText || ''} !important`,
                            '& .MuiListItemIcon-root, .MuiListItemText-root': {
                                color: `${theme.palette.error.contrastText || ''} !important`
                            },
                            '&:hover': {
                                backgroundColor: `${theme.palette.error.dark || ''} !important`
                            }
                        }}
                    >
                        <ListItemIcon>
                            <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('invitation_menu_button_label')} />
                    </ListItemButton>
                )
            ) : (
                <></>
            ),
        [inviteUrl, type, t, handleButtonClick, selected, handleMenuItemClick, theme]
    );

    return button;
};

export default InviteButton;
