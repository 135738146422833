import { Components } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - BADGE ||============================== //

const Badge = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiBadge: {
            styleOverrides: {
                standard: {
                    minWidth: theme.spacing(2),
                    height: theme.spacing(2),
                    padding: theme.spacing(0.5)
                }
            }
        }
    };
};

export default Badge;
