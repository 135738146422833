import i18next from 'i18next';
import i18n from 'lang/I18n';

export const isErrorWithMessage = (obj: unknown) => {
    let result = false;
    if (obj && typeof obj === 'object') {
        const record = obj as Record<string, unknown>;
        result = typeof record.message === 'string';
    }
    return result;
};

export const isApiErrorWithI18nMessage = (obj: unknown) => {
    console.log('isApiErrorWithI18Message', obj);
    let result = isErrorWithMessage(obj);
    if (result) {
        const record = obj as Record<string, unknown>;
        result = i18next.exists(record.message as string);
    }
    return result;
};

export const getErrorMessage = (error: unknown) => {
    let result = undefined;
    if (error !== undefined && error !== null) {
        if (typeof error === 'string') {
            result = error;
        } else if (isErrorWithMessage(error)) {
            const record = error as Record<string, unknown>;
            if (isApiErrorWithI18nMessage(error)) {
                result = i18n.t(record.message as string, record.messageParameters as { [key: string]: string });
            } else {
                result = record.message as string;
            }
        } else {
            try {
                result = JSON.stringify(error);
            } catch {
                result = String(error);
            }
        }
    }
    return result;
};
