import { Grid, Typography } from '@mui/material';
import Renew from 'pages/beetalk/Renew';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from 'store/reducers';
import { WhitelabellingContext } from '.';

export const WhitelabelledRenew: React.FC = () => {
    const whitelabellingContext = useContext(WhitelabellingContext);
    const { username } = useSelector((state: StateType) => state.auth);

    if (whitelabellingContext.paymentsPageUrl === 'disabled') {
        return (
            <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ height: '80%' }}>
                <Grid item xs={12}>
                    <Typography variant="h1" textAlign="center">
                        Per estendere la propria licenza contattare il proprio rivenditore
                    </Typography>
                </Grid>
            </Grid>
        );
    } else if (whitelabellingContext.paymentsPageUrl) {
        window.location.href = `${whitelabellingContext.paymentsPageUrl}?username=${encodeURIComponent(String(username))}`;
        return null;
    } else {
        return <Renew />;
    }
};
