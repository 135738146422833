import { User } from 'apis/types';
import { get as getLoggedUser } from 'apis/user';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { StateType } from 'store/reducers';
import AppContext, { AppContextDataType } from '.';

const AppProvider: FC<PropsWithChildren> = ({ children }) => {
    const { accessToken } = useSelector((state: StateType) => state.auth);
    const [ready, setReady] = useState(false);
    const [loggedUser, setLoggedUser] = useState<User | undefined>();
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const location = useLocation();

    useEffect(() => {
        void (async () => {
            if (accessToken) {
                try {
                    const loggedUser = await getLoggedUser(accessToken);
                    setLoggedUser(loggedUser);
                } catch (error) {
                    console.error(error);
                    setLoggedUser(undefined);
                }
            } else {
                setLoggedUser(undefined);
            }
            setReady(true);
        })();
    }, [accessToken, location]);

    const providedData: AppContextDataType = useMemo(
        () => ({
            loggedUser,
            setLoggedUser,
            openInviteDialog,
            setOpenInviteDialog
        }),
        [loggedUser, openInviteDialog]
    );

    return <AppContext.Provider value={providedData}>{ready ? children : <></>}</AppContext.Provider>;
};

export default AppProvider;
