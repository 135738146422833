// material-ui
import { Box, List, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'routes/RoutesTypes';
import { StateType } from 'store/reducers';
// project import
import NavItem from './NavItem';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

export interface NavGroupProps {
    group: Route;
    level?: number;
}

const NavGroup: React.FC<NavGroupProps> = ({ group, level = 0 }): JSX.Element => {
    const { title, children } = group;

    const menu = useSelector((state: StateType) => state.menu);
    const { drawerOpen } = menu;

    const key = useMemo(() => `${title?.toLowerCase()?.replaceAll(/[^a-z0-9]+/gm, '') || ''}`, [title]);

    const items = children
        ?.filter((route) => route && route.title)
        .map((route: Route, index: number) =>
            route.path ? (
                <NavItem key={`item-${key}-${index}`} route={route} level={level + 1} />
            ) : (
                <NavGroup key={`group-${key}-${index}`} group={route} level={level + 1} />
            )
        );

    return (
        <List
            subheader={
                title &&
                drawerOpen && (
                    <Box sx={{ pl: 3, mb: 1.5 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {title}
                        </Typography>
                    </Box>
                )
            }
            sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
        >
            {items}
        </List>
    );
};

export default NavGroup;
