import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dialog, { DialogType } from '.';

type MessageDialogType = Omit<DialogType, 'neutralAction'> & {
    continueLabel?: string;
    onContinue?: () => void;
};

const MessageDialog: React.FC<MessageDialogType> = ({ continueLabel, onContinue, children, ...props }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClick={(e) => e.stopPropagation()}
            neutralAction={
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        onContinue?.();
                    }}
                >
                    {continueLabel || t('continue')}
                </Button>
            }
            {...props}
        >
            {children}
        </Dialog>
    );
};

export default MessageDialog;
