import { ShoppingCart } from '@mui/icons-material';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { Price } from 'apis/price';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from 'store/reducers';
import { getErrorMessage } from 'utils/errors';
import { localizePrice } from 'utils/numbers';
import { Payment } from 'utils/payments';
import { get as getPaymentData, makePayment } from '../../../apis/payments';

export interface PaymentOptionProps {
    trial?: boolean;
    description?: string;
    price: number;
    discountedPrice?: number;
    vattedPrice: number;
    vat: number;
    months: number;
    priceList: Price;
    setError?: Dispatch<SetStateAction<string | undefined>>;
}

const PaymentOption: React.FC<PaymentOptionProps> = ({
    trial = false,
    description,
    price = 0,
    discountedPrice,
    vattedPrice = 0,
    vat = 0,
    months = 1,
    setError,
    priceList
}): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useSelector((state: StateType) => state.auth);

    const amount = useMemo(() => Math.max(discountedPrice ? discountedPrice : price, 0), [discountedPrice, price]);
    const years = useMemo(() => Math.floor(months / 12), [months]);
    const yearMonths = useMemo(() => months % 12, [months]);

    const yearsLabel = useMemo(() => (years ? `${years} ${t('year', { count: years })}` : ''), [t, years]);
    const yearMonthsLabel = useMemo(() => (yearMonths ? `${yearMonths} ${t('month', { count: yearMonths })}` : ''), [t, yearMonths]);

    const handleClick = () => {
        if (accessToken) {
            getPaymentData(
                accessToken,
                {
                    urlBack: `${process.env.REACT_APP_URL ?? 'http://localhost:3000'}/license/renew`,
                    urlDone: `${process.env.REACT_APP_URL ?? 'http://localhost:3000'}/license/renew`,
                    amount: Math.round(vattedPrice * 100) // Altrimenti amount * 100 potrebbe ritornare cose come 7523.999999999 invece di 7524
                },
                months,
                priceList
            )
                .then((res) => {
                    if (res) {
                        console.log('Response', res);
                        const payment = new Payment(res.paymentData);
                        console.log('Payment', payment);
                        makePayment(accessToken, payment.paymentData, months, res.price)
                            .then(() => {
                                window.location.href = payment.getRedirectUrl();
                            })
                            .catch((err) => {
                                console.error('ERROR', err);
                                setError?.(getErrorMessage(err));
                            });
                    }
                })
                .catch((err) => {
                    console.error('ERROR', err);
                    setError?.(getErrorMessage(err));
                });
        }
    };

    return (
        <Paper>
            <Stack p={2} spacing={2}>
                <Stack direction="row" alignItems="baseline">
                    <Typography variant="h3">
                        {!trial ? '+ ' : ''}
                        {years && yearMonths ? t('x_and_y', { x: yearsLabel, y: yearMonthsLabel }) : yearsLabel || yearMonthsLabel || ''}
                    </Typography>
                </Stack>
                {
                    <Typography variant="h6" color="textSecondary" my={1}>
                        {discountedPrice &&
                            discountedPrice < price &&
                            priceList.discount?.find((row) => row.months === months)?.description?.it}
                    </Typography>
                }
                <Stack alignItems="end">
                    <Stack direction="row" alignItems="baseline" justifyContent="end" spacing={2}>
                        {discountedPrice && discountedPrice < price && (
                            <Typography variant="h6" color="textSecondary" style={{ textDecoration: 'line-through' }} my={1}>
                                {localizePrice(price)} €
                            </Typography>
                        )}
                        <Typography variant="h4">{localizePrice(amount)} €</Typography>
                    </Stack>
                    <Typography variant="h6">
                        +{t('vat')?.toUpperCase() || ''} {vat} %
                    </Typography>
                    <Typography variant="h3">
                        {t('total')}: {localizePrice(vattedPrice)} €
                    </Typography>
                </Stack>
                <Button variant="outlined" startIcon={<ShoppingCart />} onClick={handleClick}>
                    {t(trial ? 'license_subscribe_action' : 'license_renew_action')}
                </Button>
            </Stack>
        </Paper>
    );
};

export default PaymentOption;
