import { ImgHTMLAttributes, ReactEventHandler, useCallback, useMemo, useState } from 'react';

export type ImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
    src: string | string[];
};

const Image: React.FC<ImageProps> = ({ src: imgSrc, onError: onImgError, alt, ...imgProps }): JSX.Element => {
    const [srcIndex, setSrcIndex] = useState(0);

    const srcLen = useMemo(() => (Array.isArray(imgSrc) ? imgSrc.length : imgSrc ? 1 : 0), [imgSrc]);
    const src = useMemo(() => (Array.isArray(imgSrc) ? imgSrc[srcIndex] : imgSrc), [imgSrc, srcIndex]);

    const handleError: ReactEventHandler<HTMLImageElement> = useCallback(
        (e) => {
            if (srcIndex < srcLen) {
                setSrcIndex(srcIndex + 1);
            } else {
                onImgError?.(e);
            }
        },
        [onImgError, srcIndex, srcLen]
    );

    return <img src={src} alt={alt} onError={handleError} {...imgProps} />;
};

export default Image;
