import { User } from 'apis/types';
import { Dispatch, SetStateAction, createContext } from 'react';

export type AppContextDataType = {
    loggedUser?: User;
    openInviteDialog: boolean;
    setOpenInviteDialog?: Dispatch<SetStateAction<boolean>>;
};

const AppContext = createContext<AppContextDataType>({
    openInviteDialog: false
});
export default AppContext;
