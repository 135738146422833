import { PaymentData } from 'apis/payments';

export class Payment {
    paymentData: PaymentData;

    constructor(paymentData: PaymentData) {
        this.paymentData = paymentData;
    }

    getQueryString() {
        return (
            `URLMS=${this.paymentData.urlMs}` +
            `&URLDONE=${this.paymentData.urlDone}` +
            `&ORDERID=${this.paymentData.orderId}` +
            `&SHOPID=${this.paymentData.shopId}` +
            `&AMOUNT=${this.paymentData.amount}` +
            `&CURRENCY=${this.paymentData.currency}` +
            (this.paymentData.exponent !== undefined ? `&EXPONENT=${this.paymentData.exponent}` : '') +
            `&ACCOUNTINGMODE=${this.paymentData.accountingMode}` +
            `&AUTHORMODE=${this.paymentData.authorMode}` +
            (this.paymentData.options !== undefined ? `&OPTIONS=${this.paymentData.options.join('')}` : '') +
            (this.paymentData.name !== undefined ? `&NAME=${this.paymentData.name}` : '') +
            (this.paymentData.surname !== undefined ? `&SURNAME=${this.paymentData.surname}` : '') +
            (this.paymentData.taxId !== undefined ? `&TAXID=${this.paymentData.taxId}` : '') +
            (this.paymentData.lockCard !== undefined ? `&LOCKCARD=${this.paymentData.lockCard}` : '') +
            (this.paymentData.commis !== undefined ? `&COMMIS=${this.paymentData.commis}` : '') +
            (this.paymentData.ordDescr !== undefined ? `&ORDDESCR=${this.paymentData.ordDescr}` : '') +
            (this.paymentData.vsid !== undefined ? `&VSID=${this.paymentData.vsid}` : '') +
            (this.paymentData.opDescr !== undefined ? `&OPDESCR=${this.paymentData.opDescr}` : '') +
            (this.paymentData.remainingDuration !== undefined ? `&REMAININGDURATION=${this.paymentData.remainingDuration}` : '') +
            (this.paymentData.userId !== undefined ? `&USERID=${this.paymentData.userId}` : '') +
            (this.paymentData.phoneNumber !== undefined ? `&PHONENUMBER=${this.paymentData.phoneNumber}` : '') +
            (this.paymentData.causation !== undefined ? `&CAUSATION=${this.paymentData.causation}` : '') +
            (this.paymentData.user !== undefined ? `&USER=${this.paymentData.user}` : '') +
            (this.paymentData.productRef !== undefined ? `&PRODUCTREF=${this.paymentData.productRef}` : '') +
            (this.paymentData.antifraud !== undefined ? `&ANTIFRAUD=${this.paymentData.antifraud}` : '') +
            (this.paymentData.dsData !== undefined ? `&3DSDATA=${this.paymentData.dsData}` : '') +
            `&PAGE=${this.paymentData.page}` +
            (this.paymentData.lang !== undefined ? `&LANG=${this.paymentData.lang}` : '') +
            `&URLBACK=${this.paymentData.urlBack}` +
            `&MAC=${this.paymentData.hmac}`
        );
    }

    getRedirectUrl() {
        return `${this.paymentData.paymentsUrl}?${this.getQueryString()}`;
    }
}
