import i18n, { Resource, ResourceKey } from 'i18next';
import { WhitelabellingParameters } from 'layout/Whitelabelling';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import it from './it.json';
import fr from './fr.json';

console.log('ORIGIN', document.location.hostname);
console.log('LANGUAGE', navigator.language);

const supportedLanguages: Resource = {
    en: {
        translation: en
    },
    it: {
        translation: it
    },
    fr: {
        translation: fr
    }
};

try {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${process.env.PUBLIC_URL}/domains/${document.location.hostname}/parameters.json`, false);
    xhr.send(null);
    if (xhr.status === 200) {
        const whitelabellingParameters = JSON.parse(xhr.responseText) as WhitelabellingParameters;
        if (whitelabellingParameters.i18n?.overrides) {
            for (const lng of Object.keys(supportedLanguages)) {
                if (lng in whitelabellingParameters.i18n.overrides) {
                    const languageStrings = supportedLanguages[lng].translation;
                    const overrideStrings = whitelabellingParameters.i18n.overrides[lng] as ResourceKey;
                    supportedLanguages[lng].translation = Object.assign(languageStrings, overrideStrings);
                }
            }
        }
    }
} catch (error) {
    console.error('I18N - WHITELABELING - ERROR', error);
}

void i18n.use(initReactI18next).init({
    resources: supportedLanguages,
    lng: navigator.language,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
