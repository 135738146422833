import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout: React.FC = (): JSX.Element => (
    <div
        style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/domains/${document.location.hostname}/background.jpg), linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(215,248,255,1) 100%)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed'
        }}
    >
        <Outlet />
    </div>
);

export default MinimalLayout;
