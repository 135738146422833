import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, OutlinedInput, Stack, Typography } from '@mui/material';
import MessageDialog from 'components/Dialog/MessageDialog';
import AppContext from 'layout/MainLayout/AppContext';
import { WhitelabellingContext } from 'layout/Whitelabelling';
import { MouseEventHandler, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const InviteDialog: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const { name: appName } = useContext(WhitelabellingContext);
    const { loggedUser, openInviteDialog, setOpenInviteDialog } = useContext(AppContext);

    const inviteUrl = useMemo(
        () => (loggedUser?.referralCode ? t('invitation_url', { referralCode: loggedUser.referralCode }) : undefined),
        [loggedUser, t]
    );

    const shareData = useMemo(
        () =>
            ({
                title: t('invitation_share_title', { appName }),
                text: t('invitation_share_text', { appName }),
                url: inviteUrl
            } as ShareData),
        [appName, inviteUrl, t]
    );

    const canShare = useMemo(() => !!navigator.canShare?.(shareData), [shareData]);

    const handleClose = useCallback(() => setOpenInviteDialog?.(false), [setOpenInviteDialog]);

    const handleCopy: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            if (inviteUrl) {
                void navigator.clipboard.writeText(inviteUrl);
            }
        },
        [inviteUrl]
    );

    const handleShare: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            if (inviteUrl && canShare) {
                void navigator.share?.(shareData);
            }
        },
        [canShare, inviteUrl, shareData]
    );

    return (
        <MessageDialog
            id={'invite-dialog'}
            open={openInviteDialog}
            icon={<PersonAddIcon />}
            title={t('invitation_dialog_title')}
            onContinue={handleClose}
            onClose={handleClose}
        >
            <Stack spacing={2}>
                <Typography>{t('invitation_dialog_instructions')}</Typography>
                <OutlinedInput
                    defaultValue={inviteUrl}
                    inputProps={{ readOnly: true }}
                    startAdornment={
                        canShare ? (
                            <IconButton aria-label="share-invite-url" size="small" onClick={handleShare}>
                                <ShareIcon />
                            </IconButton>
                        ) : (
                            <IconButton aria-label="copy-invite-url" size="small" onClick={handleCopy}>
                                <ContentCopyIcon />
                            </IconButton>
                        )
                    }
                    color="success"
                />
                <Typography mt={3} whiteSpace="pre-wrap">
                    {t('invitation_dialog_bonus')}
                </Typography>
            </Stack>
        </MessageDialog>
    );
};

export default InviteDialog;
