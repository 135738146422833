import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

const Typography = (): Components<Theme> => {
    return {
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 12
                }
            }
        }
    };
};

export default Typography;
