import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export type WhitelabellingParameters = {
    title: string;
    footer?: string;
    name: string;
    authorPage?: string;
    paymentsPageUrl?: string;
    i18n?: {
        overrides?: {
            [key: string]: any;
        };
    };
    meta?: {
        keywords: string[];
        author: string;
        description: string;
    };
};

const defaultWhitelabellingParameters: WhitelabellingParameters = {
    title: '',
    footer: '',
    name: '',
    authorPage: '',
    meta: {
        keywords: [],
        author: '',
        description: ''
    }
};

export const WhitelabellingContext = createContext<WhitelabellingParameters>(defaultWhitelabellingParameters);

export const Whitelabelling: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
    const [parameters, setParameters] = useState(defaultWhitelabellingParameters);

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/domains/${document.location.hostname}/parameters.json`, {
            redirect: 'error',
            headers: { Accept: 'application/json' }
        })
            .then((response) => {
                response
                    .json()
                    .then((parameters: WhitelabellingParameters) => {
                        setParameters(parameters);
                    })
                    .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <HelmetProvider>
            <WhitelabellingContext.Provider value={parameters}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#1f1f1f" />
                    <meta name="title" content={parameters.title} />
                    <meta name="description" content="Servizio di segreteria telefonica con trascrizione dei messaggi vocali" />
                    <meta name="keywords" content={[...(parameters.meta?.keywords ?? []), 'trascrittore'].join(', ')} />
                    {parameters.meta?.author && <meta name="author" content={parameters.meta.author} />}
                    <link rel="icon" href={`${process.env.PUBLIC_URL}/domains/${document.location.hostname}/favicon.ico`} />
                    <title>{parameters.title}</title>
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap&family=Public+Sans:wght@400;500;600;700"
                        rel="stylesheet"
                    />
                </Helmet>
                {children}
            </WhitelabellingContext.Provider>
        </HelmetProvider>
    );
};
