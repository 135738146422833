import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TABS ||============================== //

const Tabs = (): Components<Theme> => {
    return {
        MuiTabs: {
            styleOverrides: {
                vertical: {
                    overflow: 'visible'
                }
            }
        }
    };
};

export default Tabs;
