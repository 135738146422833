// material-ui
import { Color, CssBaseline, StyledEngineProvider } from '@mui/material';
import {
    Components,
    createTheme,
    Palette as ThemePalette,
    SimplePaletteColorOptions,
    Theme,
    ThemeOptions,
    ThemeProvider
} from '@mui/material/styles';
import { PaletteAugmentColorOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { PropsWithChildren, useMemo } from 'react';
import componentsOverride from './overrides';
// project import
import Palette from './palette';
import CustomShadows, { CustomShadowsProps } from './shadows';
import Typography from './typography';

export type CustomColor = Color & {
    0: string;
    A50: string;
    A800: string;
};

export type CustomPaletteColorOptions = SimplePaletteColorOptions &
    Partial<CustomColor> & {
        lighter?: string;
        darker?: string;
        A300?: string;
    };

export type CustomPalette = Omit<
    ThemePalette,
    'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | 'grey' | 'augmentColor'
> & {
    primary: CustomPaletteColorOptions;
    secondary: CustomPaletteColorOptions;
    error: CustomPaletteColorOptions;
    warning: CustomPaletteColorOptions;
    info: CustomPaletteColorOptions;
    success: CustomPaletteColorOptions;
    grey: CustomColor;
    augmentColor?: (options: PaletteAugmentColorOptions) => CustomPaletteColorOptions;
};

export type CustomTheme = Omit<Theme, 'palette'> & {
    customShadows: CustomShadowsProps;
    palette: CustomPalette;
};

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

const ThemeCustomization: React.FC<PropsWithChildren> = ({ children }) => {
    const theme: Theme = Palette('light');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography: TypographyOptions = Typography(`'Roboto', 'Public Sans', sans-serif`);
    const themeCustomShadows: CustomShadowsProps = useMemo(() => CustomShadows(theme), [theme]);

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536
                }
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography
        }),
        [theme, themeTypography, themeCustomShadows]
    );

    const themes = createTheme(themeOptions) as CustomTheme;
    themes.components = componentsOverride(themes) as Components<Omit<Theme, 'unstable_sx'>>;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemeCustomization;
