import { Components } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - CHECKBOX ||============================== //

const Checkbox = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: theme.palette.secondary[300]
                }
            }
        }
    };
};

export default Checkbox;
