// third-party
import { Components } from '@mui/material/styles';
import { merge } from 'lodash';
import { CustomTheme } from 'themes';
// project import
import Badge from './Badge';
import Button from './Button';
import CardContent from './CardContent';
import Checkbox from './Checkbox';
import Chip from './Chip';
import IconButton from './IconButton';
import InputLabel from './InputLabel';
import LinearProgress from './LinearProgress';
import Link from './Link';
import ListItemIcon from './ListItemIcon';
import ListItemText from './ListItemText';
import OutlinedInput from './OutlinedInput';
import Tab from './Tab';
import TableCell from './TableCell';
import Tabs from './Tabs';
import Toolbar from './Toolbar';
import Typography from './Typography';

// ==============================|| OVERRIDES - MAIN ||============================== //

const ComponentsOverrides = (theme: CustomTheme): Components<CustomTheme> => {
    return merge(
        Button(theme),
        Badge(theme),
        CardContent(),
        Checkbox(theme),
        Chip(theme),
        IconButton(theme),
        InputLabel(theme),
        LinearProgress(),
        Link(),
        ListItemIcon(),
        ListItemText(theme),
        OutlinedInput(theme),
        Tab(theme),
        TableCell(theme),
        Tabs(),
        Toolbar(theme),
        Typography()
    ) as Components<CustomTheme>;
};

export default ComponentsOverrides;
