import config from 'config';
import http from './http';
import { ServerResponse } from './types';

// Associazione tra numero di mesi e prezzo corrispondente
export interface PriceRecord {
    months: number;
    price: number;
    vat: number;
}

export interface Price {
    base: PriceRecord[];
    discount?: Array<PriceRecord & { description?: { it?: string; en?: string } }>;
    vatted: PriceRecord[];
    orderId: string;
    hash: string; // Per autenticare i prezzi
}

// getAll perché potrei prevedere anche una getMonthly o una getYearly
export const getAll = async (accessToken: string): Promise<Price | undefined> => {
    const url = encodeURI(`${config.serverUrl}/price/all`);
    console.log('PRICE - GET - URL', url);
    const response: ServerResponse<Price> = await http.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (!response.data.code) throw response.data;
    return response.data.object;
};
