import { Stack } from '@mui/material';
import InviteButton from 'components/invitation/InviteButton';

// ==============================|| DRAWER FOOTER ||============================== //

export interface DrawerFooterProps {
    open?: boolean;
}

const DrawerFooter: React.FC<DrawerFooterProps> = ({ open }): JSX.Element => {
    return (
        <Stack m={2}>
            <InviteButton type="expanded" />
        </Stack>
    );
};

export default DrawerFooter;
