import i18n from 'lang/I18n';
import { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from 'store/reducers';
import { getLanguageByPrefix } from 'utils/phone';

export type InternationalizerProps = PropsWithChildren;

const LanguageSelector: React.FC<InternationalizerProps> = ({ children }) => {
    const { phone } = useSelector((state: StateType) => state.auth);

    useEffect(() => {
        console.log('LANGUAGE SELECTOR - PHONE', phone);
        if (phone) {
            void i18n.changeLanguage(getLanguageByPrefix(phone, 'it'));
        } else {
            void i18n.changeLanguage(navigator.language);
        }
    }, [phone]);

    return <>{children}</>;
};

export default LanguageSelector;
